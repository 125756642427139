import { db } from '@/config/firebase';

async function getNotificationById(id) {
    let query = db.collection('notifications').doc(id);
    const querySnapshot = await query.get();

    let notificationObj = {};
    querySnapshot.forEach(item => {
        notificationObj = item.data();
        notificationObj.id = item.id;
    });

    return notificationObj;
}

async function setAsViewed(id) {
    let query = db.collection('notifications').doc(id);
    return query.set({ isViewed: true }, { merge: true });
}

function generateQuery(filter) {
    let query = db.collection("notifications");

    if (filter.email && filter.email.length > 0) {
        query = query.where('recipient', '==', filter.email)
    }
    query = query.orderBy("dateSent", "desc");

    return query;
}

function getNotificationListener(filter, callback) {
    let query = generateQuery(filter);
    let listener = query.onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
            callback(change.type, change.doc.data());
        });
    });
    return listener;
}

export default {
    getNotificationById,
    setAsViewed,
    getNotificationListener
}