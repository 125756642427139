<template>
	<div class="card shadow-sm mb-2" @mouseover="hover = true" @mouseleave="hover = false" :class="{ shadow: hover }"
		@click="onClickItem(item)">
		<div class="card-body p-1" :class="!item.isViewed ? 'unread-color' : ''">
			<div class="form-row">
				<div class="col-md-2 pt-3 pl-3">
					<img :src="iconRenderer(item.type)" alt width="45" />
				</div>
				<div class="col-md-10 pt-2">
					<div class="row chat-header">
						<div class="col-md-12 text-left">
							<h6>{{ item.title }}</h6>
						</div>
					</div>
					<div class="row chat-body">
						<div class="col-md-12">{{ item.content }}</div>
					</div>
					<div v-if="dispatchId" class="row chat-body">
						<div class="col-md-12">
							<b-link @click="openDispatchDetails(item)" class="pl-0">See Details of {{ dispatchId }}</b-link>
						</div>
					</div>
					<div class="row chat-footer">
						<div class="col-md-12 text-right" style="color: #999b9e">
							<time-ago :refresh="60" :datetime="dateSent" locale="en" :tooltip="true"
								:style="{ 'font-size': '8px' }" long />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// Component
import dispatch from '@/assets/images/notifications/dispatch.svg';
import inTransit from '@/assets/images/notifications/in-transit.svg';
import receive from '@/assets/images/notifications/receive.svg';
import welcome from '@/assets/images/notifications/welcome.svg';
import locked from '@/assets/images/notifications/locked.svg';
import changeLocation from '@/assets/images/notifications/change-location.svg';

// DAO
import dispatchDAO from '@/database/dispatches';
import notificationDAO from '@/database/notifications';

// Util
import { DateUtil } from '@/utils/dateutil';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import TimeAgo from 'vue2-timeago';


export default {
	name: 'NotificationItem',
	components: { TimeAgo },
	data() {
		return {
			hover: false,
			icons: {
				dispatch,
				inTransit,
				receive,
				welcome,
				locked,
				changeLocation,
			},
		};
	},
	props: {
		item: {
			type: Object,
			required: true,
		},
	},
	computed: {
		dateSent() {
			return DateUtil.getFormattedDateWithTime(this.item.dateSent);
		},
		dispatchId() {
			const item = this.item ? this.item : {};
			return item.meta && item.meta.dispatchId ? item.meta.dispatchId : null;
		},
	},
	methods: {
		async onClickItem(item) {
			if (item.isViewed) return;

			if (item && item.id) {
				try {
					await notificationDAO.setAsViewed(item.id);
				} catch (error) {
					this.$toaster.error('Error loading data. Please reload the page again.');
				}
			}
		},
		iconRenderer(type) {
			let icon;
			if (type === config.notificationType.NEW_COMPANY) {
				icon = this.icons.welcome;
			} else if (type === config.notificationType.DISPATCH_DEPLOYED) {
				icon = this.icons.dispatch;
			} else if (type === config.notificationType.DISPATCH_IN_TRANSIT) {
				icon = this.icons.inTransit;
			} else if (type === config.notificationType.DISPATCH_RECEIVED) {
				icon = this.icons.receive;
			} else if (type === config.notificationType.LOCKED_ACCOUNT) {
				icon = this.icons.locked;
			} else if (type === config.notificationType.UNLOCKED_ACCOUNT) {
				icon = this.icons.welcome;
			} else if (type === config.notificationType.CHANGE_LOCATION) {
				icon = this.icons.changeLocation;
			} else {
				// Default mail icon
				icon = 'https://images.clipartlogo.com/files/istock/previews/7702/77021021-email-colored-vector-icon.jpg';
			}

			return icon;
		},
		async openDispatchDetails(notification) {
			const dispatchId = notification.meta ? notification.meta.dispatchId : null;

			if (dispatchId) {
				let dispatchObj = await dispatchDAO.getDispatchByDispatchId(dispatchId);
				EventBus.$emit('onShowDispatchDetails', dispatchObj);
			}
		},
	},
};
</script>

<style scoped>
.card {
	width: 376px;
	border: none;
	cursor: pointer;
}

.chat-header {
	padding: 4px 10px;
}

.chat-body {
	padding: 0px 10px 0px 10px;
}

.chat-footer {
	padding: 5px 10px;
}

.unread-color {
	background-color: #e7edf8;
}
</style>