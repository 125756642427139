<template>
	<b-modal id="edit-asset-issuance" size="xl" title="" ref="modal" ok-title="Save" @ok="handleOk"
		@show="onReset" :cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons"
		:no-close-on-backdrop="true">
		<template #modal-title>
			Edit Asset Issuance <b class="numFont">{{ form.issuanceId }}</b>
		</template>
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<b-form @submit.stop.prevent="handleSubmit" novalidate>
			<b-container fluid>
				<div>
					<b-tabs>
						<b-tab title="Primary Information" active>
							<b-row class="my-2">
								<b-col sm="8">
									<b>PRIMARY INFORMATION</b>
								</b-col>
							</b-row>
							<b-row class="my-3">
								<b-col lg="6" md="12" sm="12">
									<b-form-group label="Company" label-for="Company" description="The company who will issue the asset">
										<b>{{ form.company }}</b>
									</b-form-group>
								</b-col>
								<b-col lg="6" md="12" sm="12">
									<b-form-group label="User" label-for="User" description="The person whom you will issue the asset to">
										<b-form-select id="User" name="User" v-model="selUser" v-validate="'selectRequired'"
											:options="userOptions" @change="onChangeUser" />
										<span v-show="errors.has('User')" class="help-block">{{
											errors.first('User')
										}}</span>
									</b-form-group>
								</b-col>
								<b-col lg="6" md="12" sm="12">
									<b-form-group label="Reason for Issuance" label-for="Reason for Issuance"
										description="Indicate your purpose for asset issuances here">
										<b-form-textarea name="Reason for Issuance" type="text" v-model="form.description"
											maxlength="200" :rows="3" v-validate="{
												required: true,
												regex: remarksRegex
											}" placeholder="Some description here" />
										<span v-show="errors.has('Reason for Issuance')" class="help-block">{{
											errors.first('Reason for Issuance')
										}}</span>
									</b-form-group>
								</b-col>
							</b-row>
						</b-tab>

						<b-tab title="Asset Issued">
							<b-row class="my-3">
								<b-col lg="6" md="12" sm="12">
									<b-form-group label="Asset Issued" label-for="Asset(s) Issued">
										<b>{{ form.assetName }} <span class="numFont">({{ form.assetCode }})</span></b>
									</b-form-group>
								</b-col>
							</b-row>
						</b-tab>
					</b-tabs>
				</div>
			</b-container>
		</b-form>
	</b-modal>
</template>

<script>
// Util
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';
import { ValidationUtil } from '@/utils/validationUtil';

// API
import assetIssuanceApi from '@/api/assetIssuanceApi';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
	name: 'edit-asset-issuance',
	components: {
		Loading,
	},
	props: {
		assetTypeOptions: {
			type: Array,
			required: true,
		},
		allCompaniesObj: {
			type: Object,
			required: true,
		},
		allUsersObj: {
			type: Object,
			required: true,
		},
		allAssetIssuancesObj: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			fields: [
				{
					key: 'asset_code',
					label: 'Asset Code',
					sortable: true,
				},
				{
					key: 'asset_name',
					label: 'Asset Name',
					sortable: false,
				},
				{
					key: 'action',
					label: 'Action',
				},
			],

			form: {
				id: '',
				issuanceId: '',
				company: '',
				companyId: '',
				firstName: '',
				lastName: '',
				userId: '',
				userCompanyId: '',
				userCompany: '',
				assetType: '',
				assetTypeId: '',
				assetCode: '',
				assetName: '',
				description: '',
				notes: '',
				status: '',
				dateIssued: null,
				issuedBy: '',
				dateCancelled: null,
				cancelledBy: '',
				dateReturned: null,
				receivedBy: '',
			},

			userOptions: [],

			selCompany: config.companyDefaultValue,
			selUser: config.userDefaultValue,

			// filtering
			selAssetType: config.assetTypeDefaultValue,
			selAssetCode: config.assetCodeDefaultValue,

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			loggedUserCompany: this.$store.getters.loggedUserCompany,
			currUserId: this.$store.getters.loggedUser.id,

			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		disableConfirmButtons() {
			return this.isLoading;
		},
		issuanceId() {
			return this.form.issuanceId;
		},
		remarksRegex() {
			return config.remarksRegex;
		}
	},
	methods: {
		onChangeUser() {
			let userId = this.selUser.id;
			let userObj = this.allUsersObj[userId];
			if (!_.isEmpty(userObj)) {
				this.form.userId = userId;
				this.form.firstName = userObj.firstName;
				this.form.lastName = userObj.lastName;
				this.form.userCompanyId = userObj.companyId;
				this.form.userCompany = userObj.company;
			}
		},
		updateUserOptions(companyId) {
			// filter user based on selected company
			this.userOptions = DropDownItemsUtil.retrieveActiveUsers(this.allUsersObj);
			this.userOptions = _.filter(this.userOptions, (o) => {
				return o.text === ' - Please select - ' || o.value.companyId === companyId;
			});
			this.selUser = config.userDefaultValue;
		},

		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			this.cleanupFormFields();

			// show loading indicator
			this.isLoading = true;

			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input.');
				// hide loading indicator
				this.isLoading = false;
				return;
			}

			this.handleSubmit();
		},
		cleanupFormFields() {
			this.form.description = ValidationUtil.removeExcessWhiteSpace(
				this.form.description
			);
		},
		updateFormValues(form) {
			let userId = this.selUser.id;
			let userObj = this.allUsersObj[userId];
			if (!_.isEmpty(userObj)) {
				form.firstName = userObj.firstName;
				form.lastName = userObj.lastName;
			}
			return form;
		},
		getParam() {
			let param = {
				currUserId: this.currUserId,
				assetIssuances: [],
			};
			param.assetIssuances.push(this.updateFormValues(this.form));

			return param;
		},
		async handleSubmit() {
			// show loading indicator
			this.isLoading = true;

			try {
				let { data } = await assetIssuanceApi.saveAssetIssuances(
					this.getParam()
				);

				if (data.isSuccess) {
					this.$toaster.success(data.message);

					let assetIssuancesArr = data.assetIssuances;
					EventBus.$emit('onCloseSaveAssetIssuance', assetIssuancesArr[0]);
					this.$refs.modal.hide();

				} else {
					this.$toaster.error(`Error saving Asset Issuance "${this.issuanceId}". Please try again.`);
				}

			} catch (_error) {
				this.$toaster.error(`Error saving Asset Issuance "${this.issuanceId}". Please try again.`);
			}

			// hide loading indicator
			this.isLoading = false;
		},

		onReset() {
			/* Reset our form values */
			let issuance = this.$store.getters.currAssetIssuance;
			this.form = {
				id: issuance.id,
				issuanceId: issuance.issuanceId,
				company: issuance.company,
				companyId: issuance.companyId,
				firstName: issuance.firstName,
				lastName: issuance.lastName,
				userId: issuance.userId,
				userCompanyId: issuance.userCompanyId,
				userCompany: issuance.userCompany,
				assetType: issuance.assetType,
				assetTypeId: issuance.assetTypeId,
				assetName: issuance.assetName,
				assetCode: issuance.assetCode,
				description: issuance.description,
				notes: issuance.notes,
				status: issuance.status,
				dateIssued: issuance.dateIssued,
				issuedBy: issuance.issuedBy,
				dateCancelled: issuance.dateCancelled,
				cancelledBy: issuance.cancelledBy,
				dateReturned: issuance.dateReturned,
				receivedBy: issuance.returnedBy,
			};

			this.updateUserOptions(this.form.userCompanyId);

			let userId = this.form.userId;
			this.selUser = DropDownItemsUtil.getUserItem(this.allUsersObj[userId]);

			// reset validation
			this.$validator.reset();
			this.errors.clear();
		}
	},
};
</script>