<template>
	<div class="app">
		<AppHeader fixed>
			<SidebarToggler class="d-lg-none" display="md" mobile />
			<b-link class="navbar-brand" to="/admin/dashboard">
				<img class="navbar-brand-full" src="img/logo.svg" width="90" height="43" alt="Ayun! Logo" />
				<img class="navbar-brand-minimized" src="img/logo-sygnet.png" width="32" height="32" alt="ARES Logo" />
			</b-link>
			<SidebarToggler class="d-md-down-none" display="lg" />
			<b-navbar-nav class="ml-auto">
				<Notifications />
				<DefaultHeaderDropdownAccnt :username="username" :userCompany="userCompany" />
			</b-navbar-nav>
		</AppHeader>
		<div class="app-body">
			<AppSidebar fixed class="sidenav">
				<SidebarHeader />
				<SidebarForm />
				<SidebarNav :navItems="nav"></SidebarNav>
				<SidebarFooter />
				<SidebarMinimizer />
			</AppSidebar>
			<main class="main">
				<div class="container-fluid">
					<router-view></router-view>
				</div>
			</main>
		</div>
		<TheFooter class="footer">
			<!--footer-->
			<div class="ml-auto">
				<a href="https://www.ayun.ph" class="footer-text">Ayun! Asset-Based</a>
				<span class="ml-1">&copy; {{ currentYear }}
					<img src="img/tawitech_logo_white.png" width="auto" height="35" />
				</span>
			</div>
		</TheFooter>

		<!-- Modals -->
		<UpdateCredentials />
	</div>
</template>

<script>
import nav from '@/containers/menu/_adminNav';
import {
	Header as AppHeader,
	SidebarToggler,
	Sidebar as AppSidebar,
	SidebarFooter,
	SidebarForm,
	SidebarHeader,
	SidebarMinimizer,
	SidebarNav,
	Aside as AppAside,
	AsideToggler,
	Footer as TheFooter,
	Breadcrumb,
} from '@coreui/vue';
import DefaultHeaderDropdownAccnt from './DefaultHeaderDropdownAccnt';
import Notifications from '../views/commons/notification/Notifications';
import UpdateCredentials from '@/views/account/UpdateCredentials';

export default {
	name: 'full',
	components: {
		AsideToggler,
		AppHeader,
		AppSidebar,
		AppAside,
		TheFooter,
		Breadcrumb,
		DefaultHeaderDropdownAccnt,
		SidebarForm,
		SidebarFooter,
		SidebarToggler,
		SidebarHeader,
		SidebarNav,
		SidebarMinimizer,
		Notifications,
		UpdateCredentials,
	},
	data() {
		return {
			nav: nav.items,
		};
	},
	computed: {
		currentYear() {
			return new Date().getFullYear();
		},
		name() {
			return this.$route.name;
		},
		list() {
			return this.$route.matched.filter(
				(route) => route.name || route.meta.label
			);
		},
		username() {
			if (this.$store.getters.email) {
				return this.$store.getters.email;
			}
			return '';
		},
		userCompany() {
			if (this.$store.getters.email) {
				return this.$store.getters.loggedUser.company;
			}
			return '';
		},
		isNewUser() {
			const currUser = this.$store.getters.loggedUser;
			return currUser.isNewUser ? true : false;
		},
	},
	mounted() {
		// if a non-superadmin user trying to access a superadmin page return to company dashboard
		if (!this.$store.getters.isSuperAdmin) {
			this.$router.push('/dashboard');
			this.$toaster.warning('You are not allowed to access this page.');
		} else {
			if (this.isNewUser) {
				this.$bvModal.show('update-credentials');
			}
		}
	},
};
</script>
<style>
.list {
    color: #122c91;
}
</style>
