<template>
    <span class="text-nowrap">
        <b-button size="sm" v-b-tooltip.hover.top="'Show/Hide Other Details'" variant="dark" @click.stop="row.toggleDetails"
            class="mr-1">
            <i class="fa fa-eye-slash" v-if="row.detailsShowing"></i>
            <i class="fa fa-eye" v-else></i>
        </b-button>
        <b-button v-show="isAllowedToEdit(row.item)" size="sm" v-b-modal.edit-connection
            v-b-tooltip.hover.top="'Edit Details'" variant="warning"
            @click.stop="updateSelConnection(row.item, row.index, $event.target)" class="mr-1">
            <i class="fa fa-pencil"></i>
        </b-button>
    </span>
</template>

<script>
// Util
import { UserUtil } from '@/utils/userutil';

// Others
import EventBus from '@/shared/event-bus';

export default {
    name: 'connection-row-actions',
    props: {
        row: {
            type: Object,
            required: true,
        },
        isViewer: {
            type: Boolean,
            required: true,
        },
        allCompaniesObj: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {

        }
    },
    methods: {
        updateSelConnection(item) {
            this.$store.commit('SET_CURR_CONNECTION', item);
            EventBus.$emit('onUpdateSelConnection', item);
        },
        isAllowedToEdit(item) {
            let fromCompanyId = item.companyId;
            let toCompanyId = item.connectedCompanyId;
            return this.isSuperAdmin 
                    || (!this.isViewer 
                        && (UserUtil.hasCompanyAccess(this.allCompaniesObj, fromCompanyId) 
                            && UserUtil.hasCompanyAccess(this.allCompaniesObj, toCompanyId)));
        }
    }
}

</script>